/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./components-generic.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./components-generic.component";
import * as i8 from "./components-generic.service";
var styles_ComponentsGenericComponent = [i0.styles];
var RenderType_ComponentsGenericComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComponentsGenericComponent, data: {} });
export { RenderType_ComponentsGenericComponent as RenderType_ComponentsGenericComponent };
function View_ComponentsGenericComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ComponentsGenericComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComponentsGenericComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enabledSpinner; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ComponentsGenericComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-components-generic", [], null, null, null, View_ComponentsGenericComponent_0, RenderType_ComponentsGenericComponent)), i1.ɵdid(1, 114688, null, 0, i7.ComponentsGenericComponent, [i8.ComponentGenericsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComponentsGenericComponentNgFactory = i1.ɵccf("app-components-generic", i7.ComponentsGenericComponent, View_ComponentsGenericComponent_Host_0, {}, {}, []);
export { ComponentsGenericComponentNgFactory as ComponentsGenericComponentNgFactory };
