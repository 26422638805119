import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatInputModule,
  MatNativeDateModule,
  MatOptionModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTooltipModule
} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { TextMaskModule } from 'angular2-text-mask';
import { ValidacionService } from '../services/validacion/validacion.service';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './accordion';
import { ControlValidacionComponent } from './control-validacion/control-validacion.component';
import { MenuItems } from './menu-items/menu-items';
import { SubirArchivoComponent } from './subir-archivo/subir-archivo.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    FlexLayoutModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatRadioModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ControlValidacionComponent,
    SubirArchivoComponent
  ],
  exports: [
    SubirArchivoComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ControlValidacionComponent,
    CommonModule,
    FormsModule,
    TextMaskModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatDividerModule,
    MatSelectModule,
    FlexLayoutModule,
    MatInputModule,
    FormsModule,
    MatRadioModule,
    MatIconModule,
    MatExpansionModule,
    MatTableModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule
  ],
  providers: [MenuItems, ValidacionService]
})
export class SharedModule {}
