import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS: Menu[] = [
  /*{
    state: 'home',
    name: 'Login',
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'reserva-mercado-pago',
    name: 'Reserva Mercado Pago',
    type: 'link',
    icon: 'pay'
  },*/
  {
    state: 'abm-evento',
    name: 'ABM Evento',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'puntoventa-por-evento',
    name: 'Puntos de Venta por Evento',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'administracion-escenarios',
    name: 'ABM Escenarios',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'puntoDeVenta',
    name: 'ABM Punto de Venta',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'categorias',
    name: 'ABM Categorìa',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'usuarios',
    name: 'ABM Usuarios',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'generador-credenciales',
    name: 'Generador de credenciales - Pulsera',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'generador-credenciales-ticket-big',
    name: 'Generador de credenciales Tarjeta',
    type: 'link',
    icon: 'lockscreen',
  },
  /*{
    state: 'abm-descuentos',
    name: 'ABM Descuentos',
    type: 'link',
    icon: 'lockscreen',
  },
  */
  {
    state: 'reportePagoFacil',
    name: 'Reporte pago facil',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'sincronizacion-molinete',
    name: 'Sincronización por molinete',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'configurations-scanner',
    name: 'Configurar aplicaciones scanner',
    type: 'link',
    icon: 'lockscreen'
  },
  {
    state: 'generador-entradas',
    name: 'Generador entradas',
    type: 'sub',
    icon: 'lockscreen',
    children: [
      {
        state: 'generador-entradas',
        name: 'Generador de entradas'
      },
      {
        state: 'generador-entradas-tekis',
        name: 'Generador de entradas Los Tekis'
      },
      {
        state: 'generador-entradas-colectividades',
        name: 'Generador de entradas Colectividades'
      },
      {
        state: 'generador-de-qr',
        name: 'Generador de QR'
      },
      {
        state: 'generador-entradas-fiesta-chula',
        name: 'Generador de entradas fiesta chula'
      }
    ]
  }/*,
  {
    state: 'administracion',
    name: 'Administracion',
    type: 'sub',
    icon: 'security',
    children: [
      {
        state: 'ventasTickets',
        name: 'Ventas de tickets'
      },
      {
        state: 'facturas',
        name: 'Factura'
      },
      {
        state: 'cancelacionReserva',
        name: 'Cancelacion Reserva',
      },
      {
        state: 'autentificacion',
        name: 'ABM Medio de Pago',
      },
      /*
      {
        state: 'nps',
        name: 'ABM Nps',
      }

    ]
  }
  /*

  {
    state: 'perfil',
    name: 'Perfil',
    type: 'link',
    icon: 'lockscreen',
    // children: [
    //   {state: 'login', name: 'LOGIN'},
    //   {state: 'registro', name: 'REGISTER'},
    // {state: 'forgot-password', name: 'FORGOT'},
    // {state: 'lockscreen', name: 'LOCKSCREEN'}
    // ]
  },
  {
    state: 'referidos',
    name: 'Referencias',
    type: 'link',
    icon: 'explore',
    // children: [
    //   {state: 'login', name: 'LOGIN'},
    //   {state: 'registro', name: 'REGISTER'},
    // {state: 'forgot-password', name: 'FORGOT'},
    // {state: 'lockscreen', name: 'LOCKSCREEN'}
    // ]
  },
  {
    state: 'wallets',
    name: 'Mis Wallets',
    type: 'link',
    icon: 'lockscreen',
  },
  {
    state: 'transacciones',
    name: 'Transacciones',
    type: 'sub',
    icon: 'explore',
    children: [
      { state: 'iniciar-transaccion', name: 'Iniciar Transaccion' },
      { state: 'listar-transacciones', name: 'Listar Transacciones' },
    ]
  },
  {
    state: 'autentificacion',
    name: 'Autentificacion',
    type: 'sub',
    icon: 'security',
    children: [
      { state: 'login', name: 'Login' },
      { state: 'registro', name: 'Registro' },
      // {state: 'forgot-password', name: 'FORGOT'},
      // {state: 'lockscreen', name: 'LOCKSCREEN'}
    ]
  },
  {
    state: 'validar-cuentas',
    name: 'Validar Cuentas',
    type: 'link',
    icon: 'explore',
  },
  */

  // {
  //   state: 'error',
  //   name: 'ERROR',
  //   type: 'sub',
  //   icon: 'error_outline',
  //   children: [
  //     {state: '404', name: '404'},
  //     {state: '503', name: '503'}
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
}
