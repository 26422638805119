import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Recurso, RespuestaPaginada } from '../models/tipos-base';

export class RecursoService<Model extends Recurso> {
  url: string;

  constructor(public http: HttpClient, public endpoint = '') {
    this.url = environment.apiUrl + this.endpoint + '/';
  }

  // constructor(public http: HttpClient) {
  //   this.url = environment.apiUrl;
  // }

  obtener(id: string): Observable<Model> {
    return this.http.get<Model>(this.url + id).pipe(take(1));
  }

  listar(params?: HttpParams): Observable<Model[] | RespuestaPaginada<Model>> {
    return this.http.get<RespuestaPaginada<Model> | Model[]>(this.url, { params }).pipe(take(1));
  }

  // listar(endpoint: string, params?: HttpParams): Observable<Model[]> {
  //   return this.http.get<Model[]>(this.url + endpoint, { params }).pipe(take(1));
  // }

  registrar(data: Model): Observable<Model> {
    return this.http.post<Model>(this.url, JSON.stringify(data)).pipe(take(1));
  }

  registrarTodo(data: Model[]): Observable<Model> {
    return this.http.post<Model>(this.url + 'jsonArray', JSON.stringify(data)).pipe(take(1));
  }

  actualizar(data: Model): Observable<Model> {
    return this.http.put<Model>(this.url, JSON.stringify(data)).pipe(take(1));
  }

  borrar(id: string): Observable<any> {
    return this.http.delete<any>(this.url + id).pipe(take(1));
  }

  listarCustom(endpoint: string, params?: HttpParams): Observable<RespuestaPaginada<Recurso> | Recurso[]> {
    return this.http.get<RespuestaPaginada<Recurso> | Recurso[]>(environment.apiUrl + endpoint + '/', { params }).pipe(take(1));
  }

  postCustom(endpoint: string, model: Model) {
    return this.http.post<RespuestaPaginada<Recurso>>(environment.apiUrl + endpoint, JSON.stringify(model)).pipe(take(1));
  }

  obtenerUnoParam(endpoint: string, params: HttpParams): Observable<Model> {
    return this.http.get<Model>(environment.apiUrl + endpoint, { params }).pipe(take(1));
  }

}
