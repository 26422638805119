import { Injectable } from '@angular/core';

@Injectable()
export class Auth {

  constructor() { }

  public tokenNotExpired() {
    const token: string = localStorage.getItem('token_id');

    return token != null && !this.isTokenExpired(token);
  }

  public getTokenExpirationDate(): Date {

    const ttl = +localStorage.getItem('token_ttl');
    const created = localStorage.getItem('created');

    const date = new Date(created); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(ttl);

    return date;
  }

  public isTokenExpired(token: string, offsetSeconds?: number): boolean {
    const date = this.getTokenExpirationDate();
    offsetSeconds = offsetSeconds || 0;

    if (date == null) {
      return false;
    }

    // Token expired?
    return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
  }

  loggedIn(): boolean { return this.tokenNotExpired(); }
  usuarioId(): string { return localStorage.getItem('user_id'); }
  usuario(): any { return localStorage.getItem('user'); }
  tokenId(): string { return localStorage.getItem('token_id'); }


}
