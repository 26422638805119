import { Component, OnInit } from '@angular/core';
import { ComponentGenericsService } from './components-generic.service';

@Component({
  selector: 'app-components-generic',
  templateUrl: './components-generic.component.html',
  styleUrls: ['./components-generic.component.scss']
})
export class ComponentsGenericComponent implements OnInit {

  enabledSpinner: boolean = false;

  constructor(
    private componentsGenericsService: ComponentGenericsService
  ) { }

  ngOnInit() {
    this.componentsGenericsService.spinners.subscribe( response => {
      this.enabledSpinner = response;
    });
  }

}
