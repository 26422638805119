import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild  {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): boolean {
    if(!this.authService.isRouteToGuard(url)){ return true};

    // ! remover esto antes de subir TODO
    let token = localStorage.getItem('token_id');
    if(token.includes('10137095625')){
      return true
    }
    if(this.authService.isLoggedIn){return true}

    this.authService.redirectUrl = url;
    this.router.navigate(['/administracion/autentificacion']);
    return false;
  }
}
