import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CampoEvento } from 'app/models/campo-evento';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';
import { environment } from './../../environments/environment';
import { Evento } from './../models/escenario';
import { Recurso } from './../models/tipos-base';
import { RecursoService } from './recurso.service';


@Injectable()
export class CampoEventoService extends RecursoService<CampoEvento> {
  constructor(public httpClient: HttpClient) {
    super(httpClient, 'camposeventoes');
  }

  getCampoEventoesByNombre(): Observable<CampoEvento[] | Recurso[]> {
    let params = new HttpParams();
    params = params.append('find', 'ByNombre');
    params = params.append('nombre', 'puntosdeventa');
    return this.http.get<CampoEvento[]>(environment.apiUrl + 'camposeventoes', { params }).pipe(take(1));
  }

  getCampoEventoesByNombreAndEventoIdOrEventoNombreOrPuntoVentaId(valor: string): Observable<CampoEvento[] | Recurso[]> {
    let params = new HttpParams();
    params = params.append('find', 'ByNombreAndEventoIdOrEventoNombreOrPuntoVentaId');
    params = params.append('nombre', 'puntosdeventa');
    params = params.append('query', valor);
    return this.http.get<CampoEvento[]>(environment.apiUrl + 'camposeventoes', { params }).pipe(take(1));
  }

  getEventosActivos(): Observable<Evento[]> {
    return this.http.get<Evento[]>(environment.apiUrl + 'eventoes/active').pipe(take(1));
  }

}
