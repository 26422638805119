/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/sidenav/typings/index.ngfactory";
import * as i2 from "@angular/material/sidenav";
import * as i3 from "@angular/cdk/bidi";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/router";
import * as i7 from "./auth-layout.component";
var styles_AuthLayoutComponent = ["[_nghost-%COMP%]     .mat-sidenav-content {width:100%; height:100%; padding: 0; background: url(\"./assets/images/night.jpg\") no-repeat;height: 100%;background-size: cover;} .mat-sidenav-container[_ngcontent-%COMP%] {z-index: 1000; width:100%; height:100%;}"];
var RenderType_AuthLayoutComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AuthLayoutComponent, data: {} });
export { RenderType_AuthLayoutComponent as RenderType_AuthLayoutComponent };
export function View_AuthLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "mat-sidenav-container", [["class", "mat-drawer-container mat-sidenav-container"]], [[2, "mat-drawer-container-explicit-backdrop", null]], null, null, i1.View_MatSidenavContainer_0, i1.RenderType_MatSidenavContainer)), i0.ɵdid(1, 1490944, null, 2, i2.MatSidenavContainer, [[2, i3.Directionality], i0.ElementRef, i0.NgZone, i0.ChangeDetectorRef, i4.ViewportRuler, i2.MAT_DRAWER_DEFAULT_AUTOSIZE, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i0.ɵqud(603979776, 1, { _drawers: 1 }), i0.ɵqud(603979776, 2, { _content: 0 }), (_l()(), i0.ɵeld(4, 16777216, null, 2, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1)._backdropOverride; _ck(_v, 0, 0, currVal_0); }); }
export function View_AuthLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_AuthLayoutComponent_0, RenderType_AuthLayoutComponent)), i0.ɵdid(1, 49152, null, 0, i7.AuthLayoutComponent, [], null, null)], null, null); }
var AuthLayoutComponentNgFactory = i0.ɵccf("app-layout", i7.AuthLayoutComponent, View_AuthLayoutComponent_Host_0, {}, {}, []);
export { AuthLayoutComponentNgFactory as AuthLayoutComponentNgFactory };
