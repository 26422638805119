import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Auth } from 'app/services/auth.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { SettingsService } from './../../shared/settings.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html'
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  private token: string = localStorage.getItem('token_id');

  url: string;
  dark: boolean;
  boxed: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  sidebarBg = true;
  currentLang = 'en';
  layoutDir = 'ltr';

  menuLayout: any = 'vertical-menu';
  selectedSidebarImage: any = 'bg-1';
  selectedSidebarColor: any = 'sidebar-orange';
  selectedHeaderColor: any = 'header-default';
  collapsedClass: any = 'side-panel-opened';

  microfrontPsCms = '';

  @ViewChild('sidemenu', { static: false })
  sidemenu;

  constructor(
    private auth: Auth,
    private router: Router,
    public menuItems: MenuItems,
    public settings: SettingsService,
    public title: Title
  ) {
    this.microfrontPsCms = environment.microfront_ps_cms.concat('?token='+ localStorage.getItem('token_id'))
  }

  ngOnInit(): void {
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');

    if (
      window.matchMedia(`(min-width: 960px)`).matches &&
      !this.isMac() &&
      !this.compactSidebar &&
      this.layoutDir !== 'rtl'
    ) {
    }

    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;
        if (this.isOver()) {
          this.sidemenu.close();
        }

        if (this.url.indexOf('bloqueo') > -1) {
          this.menuToggleFunc();
        }

        if (
          window.matchMedia(`(min-width: 960px)`).matches &&
          !this.isMac() &&
          !this.compactSidebar &&
          this.layoutDir !== 'rtl'
        ) {
        }
      });
    this.onSelectedSidebarColor('sidebar-orange');

    if (this.token == null) {
      if (!this.auth.usuarioId() || (!this.auth.loggedIn() && this.token == null)) {
        // this.router.navigate(['autentificacion']);
        this.router.navigate(['/autentificacion/login']);
        this.title.setTitle('');
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick(e: any) {
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ');
    setTimeout(() => {
      if (
        window.matchMedia(`(min-width: 960px)`).matches &&
        !this.isMac() &&
        !this.compactSidebar &&
        this.layoutDir !== 'rtl'
      ) {
      }
    }, 350);
  }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  isOver(): boolean {
    if (this.url !== undefined) {
      if (this.url.indexOf('/administracion-escenarios/abm-sectores/sector') > -1) {
        return true;
      }
    }
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  menuMouseOver(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
      this.sidemenu.mode = 'side';
    }
  }

  menuToggleFunc() {
    this.sidemenu.toggle();

    if (this.collapsedClass === 'side-panel-opened') {
      this.collapsedClass = 'side-panel-closed';
    } else {
      this.collapsedClass = 'side-panel-opened';
    }
  }

  changeMenuLayout(value) {
    if (value) {
      this.menuLayout = 'top-menu';
    } else {
      this.menuLayout = 'vertical-menu';
    }
  }

  onSelectedSidebarColor(selectedClass) {
    this.selectedSidebarColor = selectedClass;
  }

  cerrarSesion(): void {
    localStorage.clear();
    this.router.navigate(['/autentificacion/login']);
  }
}
