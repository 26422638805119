import { Archivo } from './archivo';
import { BaseAudit } from './base-audit';
import { BaseEstado } from './base-estado';

export class Usuario extends BaseAudit {
  acepto?: boolean;
  username?: string;
  id?: string;
  nombre?: string;
  apellido?: string;
  email?: string;
  dni?: string;
  codigoReferido?: string;
  habilitado?: boolean;
  telefono?: string;
  CBU?: number;
  password?: string;
  terminosAceptados?: string;
  terminosVersion?: string;
  CUIT?: string;
  sexoId?: number;
  fechaDeNacimiento?: Date;
  validacion?: Archivo;
  idEstado?: string;
  celular?: string;
  cp?: string;
  direccion?: string;
  clave?: string;
  claveRep?: string;
  novedades?: boolean;
  sexo?: any;
  value: any;

  roles?: any;

  isCtaCte?: boolean;
}

export class Sexo extends BaseEstado {
}

export class EstadoUsuario extends BaseEstado {
}


