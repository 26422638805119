import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  esAdmin = localStorage.getItem('admin');

  // store the URL so we can redirect after logging in
  redirectUrl: string;
  mapUrlsToGuard: string[] = [
    '/administracion-escenarios',
    '/abm-evento',
    '/sincronizacion-molinete'
  ]

  isRouteToGuard(url: string): boolean {
    if(this.mapUrlsToGuard.includes(url)) {
      return true
    }
    return false;
  }

  logout(): void {
    this.isLoggedIn = false;
  }
}
