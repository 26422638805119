import * as i0 from "@angular/core";
export class AuthService {
    constructor() {
        this.isLoggedIn = false;
        this.esAdmin = localStorage.getItem('admin');
        this.mapUrlsToGuard = [
            '/administracion-escenarios',
            '/abm-evento',
            '/sincronizacion-molinete'
        ];
    }
    isRouteToGuard(url) {
        if (this.mapUrlsToGuard.includes(url)) {
            return true;
        }
        return false;
    }
    logout() {
        this.isLoggedIn = false;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
