import { Observable, Subject } from "rxjs";
import * as i0 from "@angular/core";
export class ComponentGenericsService {
    constructor() {
        this.spinner$ = new Observable();
        this.spinners = new Subject();
    }
    activedSpinner(actived) {
        this.spinners.next(actived);
    }
    ;
}
ComponentGenericsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ComponentGenericsService_Factory() { return new ComponentGenericsService(); }, token: ComponentGenericsService, providedIn: "root" });
