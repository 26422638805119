import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { take } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { CodigoDescuento } from './../models/codigo-descuento';
import { RecursoService } from './recurso.service';

@Injectable()
export class CodigoDescuentoService extends RecursoService<CodigoDescuento> {

    constructor(public http: HttpClient) {
        super(http, 'codigodescuentoes');
    }

    obtenerCodigosDescuentoFindBytipoCodigoId(tipoCodigoId: number, page: number): Observable<CodigoDescuento[]> {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('tipoCodigoId', tipoCodigoId.toString());
        params = params.append('find', 'ByTipoCodigoId');
        return this.http.get<CodigoDescuento[]>(environment.apiUrl + 'codigodescuentoes' + '/', { params }).pipe(take(1));
    }

    findSizeBytipoCodigoId(tipoCodigoId: number): Observable<any> {
        let params = new HttpParams();
        params = params.append('tipoCodigoId', tipoCodigoId.toString());
        params = params.append('find', 'SizeByTipoCodigoId');
        return this.http.get<any>(environment.apiUrl + 'codigodescuentoes' + '/', { params }).pipe(take(1));
    };

    sendExcel(tipoCodigoId: string | number, file) {

        const formData = new FormData();
        formData.append('codigoDescuentoId', tipoCodigoId.toString())
        formData.append('file', file);

        const headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data;'
        });

        return this.http.post(environment.url_host_permissions + 'ms-evento/descuentosector/codigodescuentoes/load-by-excel', formData, { headers: headers });
    }

    deleteByTipoCodigoId(tipoCodigoId: number): Observable<any> {

        return this.http.delete<any>(environment.apiUrl + `codigodescuentoes/deleteByTipoCodigoId/${tipoCodigoId}`).pipe(take(1));
    }

    findCodigosDescuentosByTipoCodigoId(tipoCodigoId: number): Observable<any> {
      //return this.http.get( environment.url_host_ms_evento + '/descuentosector/codigodescuentoes/' + tipoCodigoId).pipe(take(1));
      return this.http.get( environment.url_host_permissions + 'ms-evento/descuentosector/codigodescuentoes/' + tipoCodigoId).pipe(take(1));
    }

    insertCodigosDescuentosByArray(tipoCodigoId: number, listCodigosDescuento: string[]): Observable<any> {
      //return this.http.post(environment.url_host_ms_evento + '/descuentosector/codigodescuentoes/' + tipoCodigoId,  listCodigosDescuento ).pipe(take(1));
      return this.http.post(environment.url_host_permissions + 'ms-evento/descuentosector/codigodescuentoes/' + tipoCodigoId,  listCodigosDescuento ).pipe(take(1));
    }

    deletedById(id: number) {
        return this.http.delete(environment.url_host_permissions + 'ms-evento/codigosdescuento/' + id).pipe(take(1));
    };

    save(codigoDescuento: any) {
        return this.http.post(environment.url_host_permissions + 'ms-evento/codigosdescuento', codigoDescuento).pipe(take(1));
    };
}
