import { Sexo, Usuario } from './../models/usuario';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable()
export class UsuarioService {

  private sexo: Sexo[] = [
    { 'id': '1', 'nombre': 'Hombre' },
    { 'id': '2', 'nombre': 'Mujer' },
    { 'id': '3', 'nombre': 'Sociedad' }
  ];

  constructor(private http: HttpClient) { }

  public obtenerUsuarios(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(environment.apiUrl + 'Usuarios');
  }
  public obtenerReferidos(codigoReferido: string): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(environment.apiUrl + 'Usuarios?filter[where][referidoId]=' + codigoReferido); // TODO
  }
  public obtenerUsuario(id: string): Observable<Usuario> {
    return this.http.get<Usuario>(environment.apiUrl + 'Usuarios/' + id);
  }

  public crearUsuario(usuario: Usuario): Observable<Usuario> {
    return this.http.post<Usuario>(environment.apiUrl + 'Usuarios', JSON.stringify(usuario));
  }

  public actualizarUsuario(id: string, usuario: Usuario): Observable<Usuario> {
    return this.http
      .put<Usuario>(environment.apiUrl + 'Usuarios/' + id, JSON.stringify(usuario));
  }

  public actualizarParcialUsuario(id: string, usuario: any): Observable<Usuario> {
    return this.http.patch<Usuario>(environment.apiUrl + 'Usuarios/' + id, JSON.stringify(usuario));
  }

  public eliminarUsuario(id: string): Observable<Usuario> {
    return this.http.delete<Usuario>(environment.apiUrl + 'Usuarios/' + id);
  }

  public eliminarTokensUsuario(id: string): Observable<any> {
    return this.http
      .delete(environment.apiUrl + 'Usuarios/' + id + '/accessTokens', { observe: 'response' });
  }
  public obtenerConteoUsuarioDNI(dni: string): Observable<boolean> {
    return this.http
      .post<{ exists: boolean }>(environment.apiUrl + 'Usuarios/dniExists', JSON.stringify({ dni: dni }), { observe: 'response' })
      .pipe(map(res => res.body.exists as boolean));
  }

  // Servicio Sexo

  public obtenerSexos(): Sexo[] {
    return this.sexo;
  }

  public obtenerSexo(id: string): Sexo {
    // return this.sexo[(+id) - 1] ;
    return this.sexo[1];
  }

  public existeEmailUsuario(email: string): Observable<boolean> {
    return this.http
      .post<{ exists: boolean }>(environment.apiUrl + 'Usuarios/emailExists', JSON.stringify({ email: email }), { observe: 'response' })
      .pipe(map(res => res.body.exists as boolean));
  }

  // Fin servicio Sexo
  public getUserForState(idState: string): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(environment.apiUrl + 'Usuarios?filter[where][idEstado]=' + idState);
  };

  esAdministrador(usuarioId: string): Observable<boolean> {
    return this.http.get<boolean>(environment.apiUrl + 'Usuarios/esAdministrador?usuarioId=' + usuarioId, { observe: 'response' })
      .pipe(map(res => res.body as boolean));
  }

  // CUIT
  generarCuit(dni: any, sexo: string): string {
    let AB, C;
    /** Verifico que el document_number tenga exactamente ocho numeros y que la cadena no contenga letras. */
    if (dni.length !== 8 || isNaN(dni)) {
      if (dni.length === 7 && !isNaN(dni)) {
        dni = '0'.concat(dni);
      } else {
        // Muestro un error en caso de no serlo.
        throw new Error('El numero de document_number ingresado no es correcto.');
      }
    }

    const HOMBRE = ['HOMBRE', 'M', 'MALE'], MUJER = ['MUJER', 'F', 'FEMALE'], SOCIEDAD = ['SOCIEDAD', 'S', 'SOCIETY'];
    /** De esta manera permitimos que el gender venga en minusculas, mayusculas y titulo.*/
    sexo = sexo.toUpperCase();

    // Defino el valor del prefijo.
    if (HOMBRE.indexOf(sexo) >= 0) {
      AB = '20';
    } else if (MUJER.indexOf(sexo) >= 0) {
      AB = '27';
    } else {
      AB = '30';
    }

    /* Los numeros (excepto los dos primeros) que le tengo que  multiplicar a la cadena formada por el prefijo y por el
     * numero de document_number los tengo almacenados en un arreglo.*/
    const multiplicadores = [3, 2, 7, 6, 5, 4, 3, 2];

    // Realizo las dos primeras multiplicaciones por separado.
    // tslint:disable-next-line:radix
    let calculo: number = ((parseInt(AB.charAt(0)) * 5) + (parseInt(AB.charAt(1)) * 4));

    /* Recorro el arreglo y el numero de document_number para realizar las multiplicaciones.*/
    for (let i = 0; i < 8; i++) {
      // tslint:disable-next-line:radix
      calculo += (parseInt(dni.charAt(i)) * multiplicadores[i]);
    }

    // Calculo el resto.
    // tslint:disable-next-line:radix
    const resto: number = (parseInt(calculo.toString()) % 11);

    /* Llevo a cabo la evaluacion de las tres condiciones para determinar el valor de C y conocer el valor definitivo de AB.*/
    if ((SOCIEDAD.indexOf(sexo) < 0) && (resto === 1)) {
      if (HOMBRE.indexOf(sexo) >= 0) {
        C = '9';
      } else {
        C = '4';
      }
      AB = '23';
    } else if (resto === 0) {
      C = '0';
    } else {
      C = 11 - resto;
    }
    return [AB, dni, C].join('-');
  }

}
