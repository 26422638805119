import { Component } from '@angular/core';

@Component({
  selector: 'app-layout',
  styles: [':host /deep/ .mat-sidenav-content {width:100%; height:100%; padding: 0; ' +
  'background: url("./assets/images/night.jpg") no-repeat;height: 100%;background-size: cover;} ' +
  '.mat-sidenav-container {z-index: 1000; width:100%; height:100%;}  '],
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent { }
// .body-login {
//   background-image: url("./../../../assets/images/login-fondo.png");
//     // background-image: url("../../../assets/images/login_fondo.png");
//     background-color: red;
// }
