import { cloneDeep } from 'lodash';
import { UsuarioService } from './../services/usuario.service';
import { Auth } from './../services/auth.service';
import { Usuario } from './../models/usuario';
import { Injectable } from '@angular/core';

@Injectable()
export class SettingsService {

  public usuario: Usuario;
  public app: any;
  public layout: any;

  constructor(private auth: Auth, private usuarioService: UsuarioService) {
    this.usuario = new Usuario();
    this.usuario.id = this.auth.usuario();
    if (!this.auth.usuario() && this.auth.usuarioId()) { this.obtenerUsuario(this.auth.usuarioId()); }
  }

  setearUsuario(usuario: any): void {
    this.usuario = cloneDeep(usuario);
    localStorage.setItem('user', JSON.stringify(this.usuario));
   }

  obtenerUsuario(id: string): void {
    this.usuarioService.obtenerUsuario(id).subscribe(usuario => this.usuario = usuario, error => { });
  }

}
