import { Routes } from '@angular/router';
import { AuthGuard } from './autentificacion/guard/auth.guard';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'administracion',
        loadChildren: () => import('./administracion/administracion.module').then(m => m.AdministracionModule)
      },
      {
        path: 'categorias',
        loadChildren: () => import('./administracion-eventos/abm-categorias/categoria.module').then(m => m.CategoriaModule)
      },
      {
        path: 'usuarios',
        loadChildren: () => import('./administracion-usuarios/administracion-usuarios.module').then(m => m.AdministracionUsuariosModule)
      },
      {
        path: 'generador-entradas',
        loadChildren: () => import('./administracion-entradas/generador-tickets/generador-tickets.module').then(m => m.GeneradorTicketsModule)
      },
      {
        path: 'administracion-escenarios',
        loadChildren: () => import('./administracion-escenarios/administracion-escenario.module').then(m => m.AdministracionEscenarioModule)
      },
      {
        path: 'puntoDeVenta',
        loadChildren: () => import('./administracion/punto-de-venta/punto-de-venta.module').then(m => m.PuntoDeVentaModule)
      },
      {
        path: 'reportePagoFacil',
        loadChildren: () => import('./administracion/reporte-pagofacil-diario/reporte-pagofacil-diario.module').then(m => m.ReportePagofacilDiarioModule)
      },
      {
        path: 'abm-evento',
        loadChildren: () => import('./administracion-eventos/abm-eventos/abmeventos.module').then(m => m.ABMEventosModule )
      },
      {
        path: 'abm-funciones-sectores',
        loadChildren: () => import('./administracion-eventos/abm-eventos/abm-funciones-sectores/abm-funciones-sectores.module').then(m => m.ABMFuncionesSectoresRoutesModule)
      },
      {
        path: 'generador-credenciales',
        loadChildren: () => import('./administracion-entradas/generador-credenciales/generador-credenciales.module').then(m => m.GeneradorCredencialesModule)
      },
      {
        path: 'generador-credenciales-ticket-big',
        loadChildren: () => import('./administracion-entradas/generador-credenciales-ticket-big/generador-credenciales-ticket-big.module').then(m => m.GeneradorCredencialesTicketBigModule)
      },
      {
        path: 'reserva-mercado-pago',
        loadChildren: () => import('./reserva-mercado-pago/reserva-mercado-pago.module').then( m => m.ReservaMercadoPagoModule )
      },
      {
        path: 'puntoventa-por-evento',
        loadChildren: () => import('./puntoventa-por-evento/puntoventa-por-evento.module').then( m => m.PuntoventaPorEventoModule )
      },
      {
        path: 'sincronizacion-molinete',
        loadChildren: () => import('./sincronizacion-molinete/sincronizacion-molinete.module').then( m => m.SincronizacionMolineteModule )
      },
      {
        path: 'configurations-scanner',
        loadChildren: () => import('./configurations-scanner/configurations-scanner.module').then( m => m.ConfigurationsScannerModule)
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'error',
        loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
      },
      {
        path: 'autentificacion',
        loadChildren: () =>
          import('./autentificacion/autentificacion.module').then(m => m.AutentificacionModule)
      }
    ]
  },
  { path: '**', redirectTo: 'session/404' }
];
