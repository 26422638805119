import { Auth } from './services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Toast, ToasterService } from 'angular2-toaster/angular2-toaster';
import { ToasterConfig } from 'angular2-toaster/src/toaster-config';
import { SettingsService } from './shared/settings.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  onlineOffline: boolean = navigator.onLine;
  mensajeErrorConexion: Toast;
  mensajeReconexion: Toast;

  public toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-center',
    showCloseButton: true,
    timeout: 2500
  });
  constructor(public settings: SettingsService, public toasterService: ToasterService) {

    this.mensajeErrorConexion = {
      type: 'error',
      title: 'Cuidado!',
      body: 'Se perdió la conexión',
      showCloseButton: true,
      timeout: 0
    };
    this.mensajeReconexion = {
      type: 'success',
      title: 'Que suerte!',
      body: 'Se recuperó la conexión',
      showCloseButton: true,
      timeout: 2000
    };
    window.addEventListener('online', () => {
      this.onlineOffline = true;
      this.toasterService.clear(this.mensajeErrorConexion.toastId, this.mensajeErrorConexion.toastContainerId);
      this.toasterService.pop(this.mensajeReconexion);
    });
    window.addEventListener('offline', () => {
      this.onlineOffline = false;
      this.toasterService.pop(this.mensajeErrorConexion);
    });
  }
}
