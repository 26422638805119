import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEsAr from '@angular/common/locales/es-AR';
import localeEsArExtra from '@angular/common/locales/extra/es-AR';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ToasterModule, ToasterService } from 'angular2-toaster/angular2-toaster';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthHttpInterceptor } from './services/auth-http.interceptor';
import { Auth } from './services/auth.service';
import { CampoEventoService } from './services/campo-evento.service';
import { CodigoDescuentoService } from './services/codigo-descuento.service';
import { UsuarioService } from './services/usuario.service';
import { SettingsService } from './shared/settings.service';
import { SharedModule } from './shared/shared.module';
import { ComponentsGenericComponent } from './components-generic/components-generic.component';


registerLocaleData(localeEsAr, 'es-AR', localeEsArExtra);

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, ComponentsGenericComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatInputModule,
    ToasterModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatToolbarModule,
    MatTabsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSelectModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    LayoutModule
  ],
  providers: [
    ToasterService,
    SettingsService,
    Auth,
    UsuarioService,
    CampoEventoService,
    CodigoDescuentoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
