import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root'})
export class ComponentGenericsService {

    spinner$: Observable<boolean> = new Observable<boolean>();
    public spinners: Subject<boolean> = new Subject();

    activedSpinner(actived) {
        this.spinners.next(actived);
    };
}